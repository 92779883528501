import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router';
import loadable from '@loadable/component';
import { ContentSwitch } from './ContentSwitch';

interface MedlemskapRouterProps {
  match: any;
}

const DocumentTypeSwitch = loadable(() => import('./DocumentTypeSwitch'), {
  resolveComponent: (components) => components.DocumentTypeSwitch,
});

const RouteComponent = ({ match }: { match: { params: { slug: string }; url: string } }): ReactElement => (
  <ContentSwitch match={match} />
);

export const MedlemskapsRouter = ({ match: medlemskapMatch }: MedlemskapRouterProps): ReactElement => (
  <Switch>
    <Route exact path={`${medlemskapMatch.path}/`}>
      <DocumentTypeSwitch slug="medlemskap" />
    </Route>
    <Route exact path={`${medlemskapMatch.path}/:slug`} component={RouteComponent} />
    <Route exact path={`${medlemskapMatch.path}/*/:slug`} component={RouteComponent} />
  </Switch>
);
